<template>
    <div>
        <template v-if="users.length">
            <div class="d-inline-block" v-for="(user, index) in unique" :key="user.id">
                <div v-if="index < 6">
                    <template>
                    <div class="text-center">
                        <v-menu
                        offset-y
                        top
                        max-height="30px"
                        dark
                        >
                        <template v-slot:activator="{ on: menu, attrs }">
                            <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">
                                <div
                                v-bind="attrs"
                                v-on="{ ...tooltip, ...menu }"
                                >
                                    <UserPhoto 
                                        class="ml-n3" 
                                        :size="35" 
                                        photoSize="small" 
                                        :id="user.userid"
                                    />
                                </div>
                            </template>
                            <span class="caption"><span class="text-capitalize">{{ user.collabRole }}</span>: {{ user.fullName }}</span>
                            </v-tooltip>
                        </template>
                        <v-list v-if="ownership">
                            <v-list-item class="d-block">
                            <v-list-item-title @click="deleteCollaborator(user)">Remove?</v-list-item-title>
                            </v-list-item>
                        </v-list>
                        </v-menu>
                    </div>
                    </template>
                </div>
                <div v-if="index == 6">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                        <v-avatar 
                        @click="dialogCollabs()"
                        class="ml-n3" 
                        v-on="on" 
                        size="35">
                            <v-btn
                                color="dark"
                            >
                                <span class="white--text headline">...</span>
                            </v-btn>
                        </v-avatar>
                        </template>
                        <span 
                        class="caption"
                        >View All</span>
                    </v-tooltip>
                </div>
            </div>
        </template>
    </div>
</template>    


<script>
import { mapState } from 'vuex'
import db from '@/firebase/init'
import _uniqBy from 'lodash/uniqBy'

export default {
    props: {
        collabs: Array,
        project: Object,
    },

    data () {
        return {
            status: [],
            users: [],
            deleteData: {
                user: [],
                collab: []
            }
        }
    },

    computed: {
        unique: function () {
            return _uniqBy(this.users, 'userid')
        },

        ...mapState({
        newCollaborator: state => state.collaborators.newCollaborator,
        deleteConfirmed: state => state.collaborators.deleteConfirmed,
        deleteCollabMultiple: state => state.collaborators.deleteCollabMultiple,
        authUser: state => state.user.user,
        }),

        ownership: function() {
            return this.project.owner === this.authUser.userid || this.authUser.role === 'admin'
        }
    },

    watch: {
        'collabs': function (val) {
            if (val.length) {
                this.getAssignedUsers()
            }
        },
        'newCollaborator' : function(val) {
            if(val.length){
                val.forEach(user => {
                    let getProjectId = user.ref.parent.path.split('/')
                    if(this.project.id == getProjectId[1]){
                        db.collection('users')
                        .where('userid', '==', user.userid)
                        .limit(1).get()
                        .then(snapshot => {
                            if(snapshot.size) {
                                let doc = snapshot.docs[0]
                                let data = doc.data()
                                data.id = doc.id
                                data.collabRole = user.role
                                data.ref = doc.ref
                                data.collabData = user
                                this.users.push(data)
                                let exist = false
                                this.collabs.forEach(collab => { 
                                    if(collab.userid == user.userid){
                                        exist = true
                                    }
                                })
                                if(!exist){
                                    this.collabs.push(user)
                                }
                            }
                        })
                        .catch(error => {
                            console.log(error.message)
                        }) 
                    }
                })
            }
        },
        'deleteConfirmed' : function(bol) {
            this.deleteCollab(bol)
        },
        'deleteCollabMultiple' : function(deletedCollabs) {
            if(this.deleteCollabMultiple.length) {
                this.updateDeletedCollabs(deletedCollabs)
            }
            
        }
    },

    /* -------------------------------------------------------------------------- */
    /*                                   METHODS                                  */
    /* -------------------------------------------------------------------------- */
    methods: {
        
        getAssignedUsers() {
            this.users = []
            this.collabs.forEach(collab => {
                db.collection('users')
                .where('userid', '==', collab.userid)
                .limit(1).get()
                .then(snapshot => {
                    if(snapshot.size) {
                        let doc = snapshot.docs[0]
                        let data = doc.data()
                        data.id = doc.id
                        data.collabRole = collab.role
                        data.ref = doc.ref
                        data.collabData = collab
                        if ((data.userid !== undefined) && (data.userid !== null) && (data.userid !== '""')){
                            this.users.push(data)
                        }   
                    }
                })
                .catch(error => {
                    console.log(error.message)
                }) 
            })
        },

        dialogCollabs() {
            this.$store.commit('collaborators/showDialog', true)
            this.$store.commit('collaborators/collaboratorListDialog', this.users)
        },

        deleteCollaborator(thisUser) {
            if(this.project.owner === this.authUser.userid || this.authUser.role === 'admin' ) {
                this.deleteData.collab = []
                this.deleteData.user = []
                this.deleteData.collab.push(thisUser.collabData)
                this.deleteData.user.push(thisUser)
                this.$store.commit('collaborators/showDialog', true)
                this.$store.commit('collaborators/deleteMessage', `Are you sure you want to delete user <strong>${thisUser.fullName}</strong> with <strong>${thisUser.collabData.role}</strong> role as your project collaborator?`)
                this.$store.commit('collaborators/setDeleteCollabFlag', true)
                this.$store.commit('collaborators/setDeleteConfirmed', false)
                this.$store.commit('collaborators/removeCollaborator', {
                    user: thisUser,
                    project: this.project,
                })
            }
        },

        deleteCollab(bol) {
            if(bol){
                if(this.deleteData.collab.length > 0){
                    this.collabs.forEach(collab => {
                        if(collab.id == this.deleteData.collab[0].id){
                            this.collabs.splice(this.collabs.indexOf(this.deleteData.collab[0]), 1)
                            this.$store.commit('collaborators/setDeleteConfirmed', false)
                        }
                    })
                }
            }
        },

        updateDeletedCollabs(deletedCollabs) {
            if(deletedCollabs.length) {
                deletedCollabs[0].forEach(collab => {
                    this.collabs.filter(collabsData => {
                        let getDeletedProjectId = collab.ref.parent.path.split('/')
                        let getCurrentProjectId = collabsData.ref.parent.path.split('/')
                        if(collabsData.userid === collab.userid && getDeletedProjectId[1] === getCurrentProjectId[1]){
                            this.collabs.splice(this.collabs.indexOf(collabsData), 1)
                        }
                    })
                })  
            }
        },

    },

    mounted() {
        if (this.collabs.length) this.getAssignedUsers()
    }

}
</script>