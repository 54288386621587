<template>
  <div>
    <v-img :src="logoUrl" max-height="200" min-height="200" aspect-ratio contain>
      <template v-slot:default>
        <div class="d-flex fill-height align-end pa-2">
        <v-tooltip 
          v-if="owner && false"
          top
        >
          <template v-slot:activator="{ on }">
          <v-avatar
            size="35"
            v-on="on"
          >
            <UserPhoto :size="35" photoSize="thumb" :id="owner.userid" />
          </v-avatar>
          </template>
          <span class="caption">Owner: {{ owner.fullName }}</span>
        </v-tooltip>

        <CollaboratorBlock 
          v-if="false"
          :collabs="projectCollaborators"
          :project="project"
        />

        <v-tooltip v-if="project.owner !== 'guest' && owner && false" top>
          <template v-slot:activator="{ on }">
          <v-avatar 
          class="ml-n3" 
          v-on="on" 
          size="35">
            <v-btn
              @click="showAddEditDialog(), collaborators()"
              color="dark"
            >
              <span class="white--text headline">+</span>
            </v-btn>
          </v-avatar>
          </template>
          <span class="caption">Add User</span>
        </v-tooltip>
        </div>
      </template>
    </v-img>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import db from '@/firebase/init'
import CollaboratorBlock from '@/components/CollaboratorBlock'

export default {
  props: {
    project: Object,
  },

data () {
  return {
    user: null,
    projectCollaborators: [],
    error: null,
    adding: false,
    deleting: false,
    logoUrl: require('@/assets/placeholder.svg')
  }
},

/* -------------------------------------------------------------------------- */
/*                                 COMPONENTS                                 */
/* -------------------------------------------------------------------------- */
  components: {
    CollaboratorBlock,
  },

/* -------------------------------------------------------------------------- */
/*                                  COMPUTED                                  */
/* -------------------------------------------------------------------------- */
computed: {
  owner: function() {
      return this.user
  },

  ...mapState({
    status: state => state.collaborators.status,
    userInvited: state => state.collaborators.userInvited,
    authUser: state => state.user.user,
  })
},

watch: {
  'userInvited': function (newCollaborator) {
    this.addInvitedCollaborator(newCollaborator)
  },

  'project': function () {
    this.checkProjectLogo()
  },
  
  'project.logo': function () {
    this.checkProjectLogo()
  }
},

/* -------------------------------------------------------------------------- */
/*                                   METHODS                                  */
/* -------------------------------------------------------------------------- */
methods: {

  showAddEditDialog(user = null) {
    this.$store.commit('users/setAddDialog', true)
    if (user) {
      this.$store.commit('users/setData', user)
    }
  },

  collaborators() {
    this.$store.commit('allProjects/setDialogUserList', true)
    this.$store.commit('collaborators/selectedProject', this.project)
    this.$store.dispatch('collaborators/getAllUsers')
  },

  allCollaborators() {
    this.getCollaborators()
  },

  getUserOwner(id) {
    db.collection('users')
    .where('userid','==',id)
    .limit(1)
    .get()
    .then(snapshot => {
      if(snapshot.size) {
        let doc = snapshot.docs[0]
        let data = doc.data()
        data.id = doc.id
        data.ref = doc.ref
        this.user = data
      }
    })
    .catch(error => {
      console.log(error.message)
    }) 
  },

  /* -------------------------------------------------------------------------- */
  /*                          COLLABORATORS IN PROJECT                          */
  /* -------------------------------------------------------------------------- */
  getCollaborators() {
      db.collection('projects').doc(this.project.id).collection('collaborators')
      .get()
      .then(snapshot => {
      if (snapshot.size) {
          
        snapshot.forEach(snapshot => {
          let doc = snapshot
          let data = doc.data()
          data.id = doc.id
          data.ref = doc.ref
          this.projectCollaborators.push(data)

          // set project collaborator by auth
          if(data.invited == this.authUser.email && data.userid == null){
            this.$store.dispatch('collaborators/setInvitedUser', {
              userid: this.authUser.userid,
              email:  this.authUser.email
            }, { root: true })
          }

        })
      }
      })
      .catch(error => {
          this.$store.dispatch('showError', error.message)
      })
  },

  addInvitedCollaborator(collaborator) {
    collaborator.forEach(_collab => {
      console.log('_collab')
      console.log(_collab)
      this.projectCollaborators.push(_collab)
    })
  },

  checkProjectLogo() {
    if (this.project && this.project.logo) {
      if (typeof this.project.logo !== 'object') {
        Promise.resolve(this.$getUrl(`logos/${this.project.logo}`))
        .then(response => {
          this.logoUrl = response
        })
      }
      else {
        this.logoUrl = this.tempUrl
      }
    }
  }

/* -------------------------------------------------------------------------- */
/*                            USER IN COLLABORATORS                           */
/* -------------------------------------------------------------------------- */


},

  created() {
    if (this.project.owner) {
        this.getUserOwner(this.project.owner)
    }
    
    this.allCollaborators()
    this.checkProjectLogo()
  },
}
</script>