<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-card :class="{ 'box-shadow' : hover }" class="transition" outlined>
        <v-avatar
          :style="{position: 'absolute', top: '2px', right: '2px', zIndex: 5}"
          v-if="collabs.includes($store.getters['user/data'].userid)"
          color="white"
          size="25"
          top
        >
          <v-icon color="grey lighten-2" dark>mdi-account-supervisor-circle</v-icon>
        </v-avatar>

        <v-card-text @click="$router.push({ name: 'Project', params: { id: project.id } })">
          <div>
            <ProjectImageBlock :project="project"/>
          </div>
          <div class="mt-4">
            <h5 class="text-center body-2 font-weight-bold">{{ project.name }}</h5>
            <p class="caption grey--text text-center mb-0">
              Created {{ project.createdAt | formatDate }} <br>
              by <owner :id="project.owner" /> 
            </p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between">
          <v-btn :to="{ name: 'Project', params: { id: project.id } }" text small color="primary" class="subtle">
            <v-icon left>mdi-palette-advanced</v-icon>
            <span class="font-weight-regular text-none">Builder</span>
          </v-btn>

          <div class="d-flex">
            <v-divider vertical class="mx-2 mt-n2 mb-n2"></v-divider>

            <v-menu bottom left offset-y transition="slide-x-transition" nudge-bottom="12">
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  class="subtle"
                  v-on="on"
                  small
                  icon
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="updateProject(project)" dense class="subtle">
                  <v-list-item-title class="primary--text">
                    <v-icon small left>mdi-form-textbox</v-icon>
                    <span>Rename</span>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item 
                  @click="moveFolder(project)" dense class="subtle"
                  v-if="!disableMoveProjectFolder"
                >
                  <v-list-item-title class="primary--text">
                    <v-icon small left>mdi-folder-upload-outline</v-icon> 
                    <span>Move To Folder</span>
                  </v-list-item-title>
                </v-list-item>
                <template v-if="!collabs.includes($store.getters['user/data'].userid) && deletable">
                  <v-list-item
                    @click="$emit('upload')"
                    class="subtle"
                    dense
                  >
                    <v-list-item-title class="primary--text">
                      <v-icon small left>mdi-upload</v-icon> 
                      <span>Upload Logo</span>
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item 
                    @click="$emit('delete')"
                    class="subtle"
                    dense 
                  >
                    <v-list-item-title class="error--text">
                      <v-icon color="error" small left>mdi-delete</v-icon> 
                      <span>Delete Project</span>
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </div>
        </v-card-actions>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import ProjectImageBlock from '@/components/ProjectImageBlock'

export default {
  props: {
    project: Object,
    deletable: Boolean,
    disableMoveProjectFolder: Boolean,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      collabs: [],
      ownerData: {},
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    owner: function () {
      return this.ownerData
    },
  },

  /* -------------------------------------------------------------------------- */
  /*                                 COMPONENTS                                 */
  /* -------------------------------------------------------------------------- */
  components: {
    ProjectImageBlock,
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  'project': function (val) {
    if (val) {
      console.log(val)
    }
  },

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */
  methods: {
    updateProject(project) {
      this.$store.commit('projects/setData', project)
      this.$emit('update')
    },

    moveFolder(project) {
      if(!this.disableMoveProjectFolder){
        this.$emit('move', project)
      }
    },

    getCollabs() {
      this.project.ref.collection('collaborators')
      .where('status', '==', 'active')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            this.collabs.push(doc.data().user)
          })
        }
      })
      .catch(error => {
        console.log(error.message)
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getCollabs()
  }
 }

</script>
