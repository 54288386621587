<template>
  <v-dialog
    :value="show"
    :max-width="$dialog.medium"
    persistent
    no-click-animation
  >
    <v-card class="py-5">
      <v-sheet :max-width="$dialog.content" class="mx-auto">
        <v-card-title class="justify-center">
          <h5 class="font-weight-bold text-h5 text-center">Delete "{{ name }}" folder?</h5>

          <v-btn
            @click="closeDialog()"
            icon
            absolute
            right
            top
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p class="text-center grey--text body-1">Please select a destination folder where to transfer the <br> projects inside this folder.</p>

          <v-alert type="error" border="left" v-if="status.error">{{ status.error }}</v-alert>

          <v-form ref="deleteFolderForm" @submit.prevent="validateForm()">
            <v-select
              v-model="newFolder"
              label="Select a folder"
              :rules="[rules.required]"
              :items="filtered"
              item-text="name"
              item-value="id"
              prepend-inner-icon="mdi-folder-outline"
              outlined
            ></v-select>

            <v-btn
              @click="validateForm()"
              :loading="status.deleting"
              color="accent"
              class="gradient"
              block
            >
              Delete
            </v-btn>
          </v-form>
        </v-card-text>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>

<script>

import rules from '@/rules'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    show: Boolean,
    name: String
  },

  data() {
    return {
      rules,
      newFolder: null,
    }
  },

  computed: {
    ...mapState({
      status: state => state.folders.status,
      folders: state => state.folders.folders,
    }),

    filtered: function () {
      return this.folders.filter(folder => {
        return folder.id !== this.$route.params.id
      })
    }
  },

  methods: {
    ...mapActions('folders', [
      'delete'
    ]),

    closeDialog() {
      this.$emit('close')
    },

    validateForm() {
      if (this.$refs.deleteFolderForm.validate()) {
        this.$emit('confirmed', this.newFolder)
      }
    }
  }
}

</script>
