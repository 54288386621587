var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.users.length)?_vm._l((_vm.unique),function(user,index){return _c('div',{key:user.id,staticClass:"d-inline-block"},[(index < 6)?_c('div',[[_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":"","top":"","max-height":"30px","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),Object.assign({}, tooltip, menu)),[_c('UserPhoto',{staticClass:"ml-n3",attrs:{"size":35,"photoSize":"small","id":user.userid}})],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(user.collabRole))]),_vm._v(": "+_vm._s(user.fullName))])])]}}],null,true)},[(_vm.ownership)?_c('v-list',[_c('v-list-item',{staticClass:"d-block"},[_c('v-list-item-title',{on:{"click":function($event){return _vm.deleteCollaborator(user)}}},[_vm._v("Remove?")])],1)],1):_vm._e()],1)],1)]],2):_vm._e(),(index == 6)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-avatar',_vm._g({staticClass:"ml-n3",attrs:{"size":"35"},on:{"click":function($event){return _vm.dialogCollabs()}}},on),[_c('v-btn',{attrs:{"color":"dark"}},[_c('span',{staticClass:"white--text headline"},[_vm._v("...")])])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("View All")])])],1):_vm._e()])}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }