<template>
  <div>
    <template v-if="!folder">
      <v-alert type="info" border="left" text>
        Folder not found
      </v-alert>
    </template>
    <template v-else>
      <AdminTitle :title="`${folder.name}`" icon="folder-open-outline">
        <template v-slot:actions>
          <div class="d-flex gap-12 align-center">
            <v-btn
              @click="getProjects(); getFolders();"
              :loading="status.getting"
              icon
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>

            <v-btn
              @click="$parent.showAdd()"
              color="accent"
              class="gradient"
            >
              Create New Project
            </v-btn>

          </div>
        </template>
      </AdminTitle>

      <div>
        <div class="mb-5 d-flex justify-space-between">
          <back text="Back To Root Folder" route="Projects" />

          <v-menu bottom left offset-y transition="slide-x-transition">
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                class="subtle"
                v-on="on"
                icon
              >
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="renameFolder()" dense class="subtle">
                <v-list-item-title class="primary--text"><v-icon small left>mdi-form-textbox</v-icon> Rename</v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteFolder()" dense class="subtle">
                <v-list-item-title class="red--text"><v-icon small left color="red">mdi-delete</v-icon> Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <preloader v-if="status.getting" />
        <v-alert type="info" border="left" v-if="!status.getting && !filtered.length" text>No project found</v-alert>
        <v-row v-if="!status.getting && filtered.length">
          <v-col cols="3" v-for="project in filtered" :key="project.id">
            <project-block
              :project="project"
              @update="$parent.showAdd()"
              @move="$parent.moveFolder"
            />
          </v-col>
        </v-row>
      </div>

      <!-- DELETE FOLDER -->
      <delete-folder
        :show="showDelete"
        :name="folder.name"
        @close="closeDeleteDialog()"
        @confirmed="deleteConfirmed"
      />
    </template>

  </div>
</template>

<script>

import ProjectBlock from '@/components/ProjectBlock'
import DeleteFolder from '@/components/DeleteFolderDialog'
import { mapState, mapActions } from 'vuex'
import _orderBy from 'lodash/orderBy'
import db from '@/firebase/init'

export default {
  name: 'Folder',

  metaInfo() {
    return {
      title: this.title
    }
  },

  data() {
    return {
      folderDialog: false,
      selectedProject: null,
      showDelete: false,
    }
  },

  components: {
    DeleteFolder,
    ProjectBlock,
  },

  computed: {
    ...mapState({
      projects: state => state.projects.projects,
      status: state => state.projects.status,
      folders: state => state.folders.folders,
    }),

    filtered: function () {
      return _orderBy(this.projects.filter(project => {
        return project.folder == this.$route.params.id
      }), ['createdAt'], ['desc'])
    },

    folder: function () {
      let folder = null
      folder = this.folders.find(folder => folder.id == this.$route.params.id)

      if (!folder) {
        folder = null
      }
     
      return folder
    },

    title: function () {
      return this.folder ? `${this.folder.name} Folder` : 'Folder'
    }
  },

  methods: {
    ...mapActions('projects', [
      'getProjects'
    ]),

    ...mapActions('folders', [
      'getFolders',
      'delete'
    ]),

    updateProject(project) {
      this.$store.commit('projects/setData', project)
      this.showAdd()
    },

    renameFolder() {
      this.$store.commit('folders/setData', this.folder)
      this.$parent.showFolderDialog()
    },

    closeFolderDialog() {
      this.$parent.closeFolderDialog()
      this.$store.commit('folders/setData', {})
    },

    deleteFolder() {
      if (this.filtered.length) {
        this.showDelete = true
      }
      else {
        Promise.all([this.delete(this.folder)])
        .then(response => {
          if (!response[0]) {
            this.$router.push({ name: 'Projects' })
          }
        })
      }
    },

    deleteConfirmed(id) {
      Promise.all([
        this.delete(this.folder)
      ])
      .then(response => {
        if (!response[0]) {

          this.filtered.forEach(project => {
            let data = {
              folder: id,
              project: project
            }

            this.$store.dispatch('projects/updateFolder', data)
          })

          this.showDelete = false
          this.$router.push({ name: 'Projects' })
        }
      })
    },

    closeDeleteDialog() {
      this.showDelete = false
    }
  },

  created() {
    if (!this.status.firstLoad) this.getProjects()
    if (!this.$store.state.folders.status.firstLoad) {
      this.$store.dispatch('folders/getFolders')
    }
    
    db.collection('folders')
    .doc(this.$route.params.id)
    .get()
    .then(doc => {
      if (!doc.exists) {
        this.$router.push({ name: 'PageNotFound' })
      }
    })
    .catch(error => {
      console.log(error.message)
    })
  }
}

</script>
